<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <WidgetToolbar
        v-if="Object.keys(widget).length !== 0"
        :widget="widget"
        closing
      />
    </b-card>
    <div
      v-if="showAlert"
      class="alert alert-danger"
    >
      <div class="alert-body">
        <strong>
          {{ $t('notifications.widget.change_type_warning') }}
        </strong>
      </div>
    </div>
    <faq
      :widget="widget"
      :widget-code="widgetCode($route.params.siteId, $route.params.widgetId, widget)"
      :interstitial-code="interstitialCode($route.params.siteId, $route.params.widgetId, widget)"
    />
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import {
  BCard,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import WidgetToolbar from '@/views/dashboard/widgets/WidgetToolbar.vue'
import widgetCode from '@/views/dashboard/widgets/widgetCode'
import interstitialCode from '@/views/dashboard/widgets/interstitialCode'

import Faq from './FaqWidget.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    Faq,
    UserToolbar,
    WidgetToolbar,
  },
  data() {
    return {
      widgetCode,
      interstitialCode,
      authUserData: getUserData(),
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      account: {},
      widget: {},
      site: {},
      showAlert: 0,
    }
  },
  created() {
    if (this.$route.query.m !== undefined && this.$route.query.m === 1) {
      this.showAlert = this.$route.query.m
    }
    this.getWidgetData()
  },
  methods: {
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.getSiteData(this.$route.params.siteId)
    },
    async getWidgetData() {
      const responseData = await useJwt.getWidget(this.$route.params.widgetId)
      this.widget = responseData.data.widget
      this.getUserData()
    },
    setBreadcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users/',
        },
        {
          text: this.account.name,
          to: `/users/${this.site.user_id}`,
        },
        {
          text: this.$t('sites.sites'),
          to: `/users/${this.$route.params.userId}/sites/`,
        },
        {
          text: this.site.title,
          to: `/users/${this.$route.params.userId}/sites/${this.site._id}`,
        },
        {
          text: this.$t('page.features.widgets'),
          to: `/users/${this.site.user_id}/sites/${this.site._id}/widgets/`,
        },
        {
          text: this.widget.title,
          active: true,
        },
      ]
    },
    async getSiteData(id) {
      const responseData = await useJwt.getSite(id)
      this.site = responseData.data.site
      this.pageTitle = this.site.title
      this.setBreadcrumbs()
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
