<template>
  <div>
    <div class="d-flex align-items-center">
      <b-avatar
        rounded
        size="42"
        variant="light-info"
        class="mr-1"
      >
        <feather-icon
          icon="AlertCircleIcon"
          size="20"
        />

      </b-avatar>
      <div>
        <h4 class="mb-0">
          FAQ
        </h4>
        <span>Let's answer some questions</span>
      </div>
    </div>
    <app-collapse
      accordion
      type="margin"
      class="mt-2"
    >
      <app-collapse-item
        :is-visible="true"
        title="Copy code here!"
      >
        <div
          v-if="widget.interstitial !== undefined && widget.interstitial.active && (widget.type === 'default' || widget.type === 'builder')"
          class="row"
        >
          <div class="col-md-6">
            <div class="alert alert-info mb-15">
              <div class="alert-body">
                Widget code: the code is inserted into the &lt;body&gt; section
              </div>
            </div>
            <b-form-textarea
              ref="tarea"
              v-model="widgetCode"
              variant="primary"
              placeholder="Code"
              rows="8"
              disabled
            />
            <b-button
              v-clipboard:copy="widgetCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-b-tooltip.hover.top="$t('widgets.copybutton')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1"
            >
              <feather-icon icon="CopyIcon" /> {{ $t('form.copy') }}
            </b-button>
          </div>
          <div class="col-md-6">
            <div class="alert alert-info mb-15">
              <div class="alert-body">
                Interstitial code: the code is inserted into the &lt;head&gt; section
              </div>
            </div>
            <b-form-textarea
              ref="tarea"
              v-model="interstitialCode"
              variant="primary"
              placeholder="Code"
              disabled
              rows="8"
            />
            <b-button
              v-clipboard:copy="interstitialCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-b-tooltip.hover.top="$t('widgets.copybutton')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1"
            >
              <feather-icon icon="CopyIcon" /> {{ $t('form.copy') }}
            </b-button>
          </div>
        </div>
        <div v-else>
          <div class="alert alert-info mb-15">
            <div class="alert-body">
              Widget code: the code is inserted into the &lt;body&gt; section
            </div>
          </div>
          <b-form-textarea
            ref="tarea"
            v-model="widgetCode"
            variant="primary"
            placeholder="Code"
            rows="8"
            disabled
          />
          <b-button
            v-clipboard:copy="widgetCode"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            v-b-tooltip.hover.top="$t('widgets.copybutton')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1"
          >
            <feather-icon icon="CopyIcon" /> {{ $t('form.copy') }}
          </b-button>
        </div>
      </app-collapse-item>

      <app-collapse-item
        title="How to add a widget to a Wordpress site?"
      >
        <p>To place a widget on the site you need:</p>
        <ul>
          <li>Enter tab Appearance>>Widgets;</li>
          <li>Select the location of the widget;</li>
          <li>Click the "Add Widget" button;</li>
          <li>Select widget type code and paste the copied widget code</li>
          <li>Press the "Update" button.</li>
        </ul>
      </app-collapse-item>
      <app-collapse-item
        title="How to add a widget to a Custom site?"
      >
        <p>To place a widget on the site you need:</p>
        <ul>
          <li>Enter tab Appearance>>Widgets;</li>
          <li>Select the location of the widget;</li>
          <li>Click the "Add Widget" button;</li>
          <li>Select widget type code and paste the copied widget code</li>
          <li>Press the "Update" button.</li>
        </ul>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import {
  BAvatar,
  BFormTextarea,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    AppCollapse,
    BFormTextarea,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
    widgetCode: {
      type: String,
      default: '',
    },
    interstitialCode: {
      type: String,
      default: '',
    },
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Widget code copied',
          icon: 'BellIcon',
        },
      })
      this.$refs.tarea.focus()
      this.$refs.tarea.select()
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy widget code!',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
